<template>
  <div>
    <a-row class="left">
        <a-col class="title">中再物贸云网依法进行主体登记，履行纳税义务</a-col>
        <a-col class="date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
        <a-divider class="main"></a-divider>
        <div class="content">
            <p><b>依据《中华人民共和国电子商务法》, 中再物贸云交易平台依法进行主体登记，履行纳税义务。</b></p>
            <br/>
            <p>根据第二章第十条：“电子商务经营者应当依法办理市场主体登记。” 本平台严格限制未办理市场主体登记的经营者通过平台进行交易经营，并且提供完善的信息采集，引导经营者通过平台提交市场主体登记申请，并通过平台相关人员为其办理。</p>
            <br>
            <p>根据第二章第十一条：“电子商务经营者应当依法履行纳税义务，并依法享受税收优惠。依照前条规定不需要办理市场主体登记的电子商务经营者在首次纳税义务发生后，应当依照税收征收管理法律、行政法规的规定申请办理税务登记，并如实申报纳税。” 本平台在交易环节即扣除经营者应缴税额，并定期为其进行记账与报税，通过统一清缴，<b>完成电商经营者周期内所有交易的增值税、附加税与个人所得税。</b></p>
            <br>
            <p>根据第二章第十四条：”电子商务经营者销售商品或者提供服务应当依法出具纸质发票或者电子发票等购货凭证或者服务单据。电子发票与纸质发票具有同等法律效力“，经营者委托平台进行开票服务，平台通过税务系统为其开具交易相关的增值税发票，通过纸制专用票或电子发票的形式提供给采购方。</p>
            <br>
            <br>
            <p>相关文件：</p>
            <p><a href="http://www.mofcom.gov.cn/article/zt_dzswf/" target="_blank">《中华人民共和国电子商务法》专题</a></p>
        </div>

        <a-row :gutter="20">
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s4')">
                    <div class="align">
                        <p class="to">上一篇</p>
                        <p class="to-title">关于个体工商户代开发票限额的通知</p>
                    </div>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s1')">
                    <div class="align">
                        <p class="to">下一篇</p>
                        <p class="to-title">中再物贸云交易平台上线</p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </a-row>

    <a-row class="right">
        <a-col class="date">其他公告</a-col>
        <a-divider class="main"></a-divider>
        
        <a-row class="r-item" v-for="item in list" :key="item.title">
            <a-col class="to-title" @click="$router.push(item.router)">{{item.title}}</a-col>
            <a-col class="r-text">{{item.text}}</a-col>
            <a-col class="r-date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
            <a-divider></a-divider>
        </a-row>
    </a-row>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {title:'中再物贸云交易平台上线啦！',text:'针对再生资源行业税票乱象问题，中再物贸云通过多年的交易经验与相关部门合作...',router: '/notice-details/s1'},
                {title:'中再物贸云交易平台执行“实名认证”入驻',text:'为确保交易自然人的知悉权，避免身份被他人借用的情况，平台卖家入驻时需...',router: '/notice-details/s3'},
                {title:'关于个体工商户代开发票限额的通知',text:'根据《国家税务总局关于统一小规模纳税人标准等若干增值税问题的公告》...',router: '/notice-details/s4'},
                {title:'增值税1%征收率即将结束',text:'小规模增值税1%征收率将于2021年12月31结束，2022年1月1日起按3%征收...',router: '/notice-details/s2'},
               ]
        }
    }
}
</script>

<style lang="less" scoped>
.left {
    @media screen and (max-width: 1440px){
        width: 60%;
    }
    display: inline-block;
    vertical-align: top;
    width: 960px;
    margin-right: 42px;
}
.right {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1440px){
        width: calc(40% - 42px);
    }
    width: 352px;
    line-height: 24px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
}
.date {
    color: #AAAAAA;
    font-size: 16px;
}
.content {
    p{
        line-height: 26px;
        text-indent: 2em;
    }
    li {
        margin-left: 3.5em;
        line-height: 26px;
        list-style: initial;
    }
}
.to-title {
    cursor: pointer;
    font-weight: 700;
    color: #666666;
    font-size: 18px;
    transition: all .1s;
    &:hover {
        color: rgba(234, 164, 0, 1);
    }
}
.next {
    margin: 62px 0;
    cursor: pointer;
    border-left: 3px solid rgba(215, 215, 215, 1);
    background-color: rgba(242, 242, 242, 1);
    height: 100px;
    position: relative;
    transition: all .1s;
    .align {
        margin-left: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        .to {
            color: rgb(170, 170, 170);
            line-height: 28px;
        }
    }
    &:hover {
        background-color: #0378e6;
        .to,.to-title {
            color: #fff !important;
        }
    }
}
.r-item {
    .r-date {
        color: #AAAAAA;
        font-size: 14px;
    }
    .r-text {
        font-size: 14px;
        color: #AAAAAA;
        margin-top: 6px;
        line-height: 24px;
    }
}
/deep/ .ant-divider-horizontal{
    margin: 12px 0;
}
.main {
    background: #d7d7d7;
    margin: 12px 0 16px 0;
}
</style>